import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSteam
} from '@fortawesome/free-brands-svg-icons'

// Styles
import "../../styles/homepage/homepage_section-steam.scss"

// markup
const SteamReminderSection = () => {
    return (
        <div className="homepage_section-dark homepage_section_steam-reminder">

            <div className="wrapper-narrow">

                <a
                    href="https://store.steampowered.com/app/1515590/Super_Snow_Tubes/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <StaticImage
                        alt="Steam logo"
                        src="../../images/homepage/steam-logo.png"
                    />
                </a>

                <p>
                    Don't forget to add Super Snow Tubes to your <strong>Steam Wishlist</strong>.
                </p>

                <p>
                    It ensures you get notified when the game comes out, including a sweet <strong>launch discount</strong>.
                </p>

                <a
                    className="button"
                    href="https://store.steampowered.com/app/1515590/Super_Snow_Tubes/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faSteam} className="icon" />
                    Add to your Wishlist
                </a>

            </div>

        </div >
    )
}

export default SteamReminderSection
import * as React from "react"

import { StaticImage, } from 'gatsby-plugin-image'

// Image
import soundtrackPolarHeadphones from '../../images/homepage/details/soundtrack-polar-headphones.gif'

// Styles
import "../../styles/homepage/homepage_section-detail.scss"

const detailSections = [
    // Hub World
    {
        imgSrc: <StaticImage
            alt=""
            src="../../images/homepage/details/tube-shop.png"
            // src="../../images/placeholders/placeholder-default.png"
            layout="fullWidth"
        />,
        heading: 'An extra cozy hub world to relax between races',
        intro: 'Take a break from the slopes and discover what else the game\'s world has to offer.',
        paragraphs: <>
            <p>
                As the great tubian philosopher Tubius Maximus once said: “Tube hard, chill hard!”
            </p>
            <p>
                And so inbetween the fierce and fun races on the slopes, you’ll be glad to regularly return to Tube Town, the game’s extra cozy hub world, located right at the bottom of Snow Tube Mountain.
            </p>
            <p>
                Whether you want to try out a different rider, have a go at beating one of the many snow tube themed mini games, make a trip to the shops, or simply meet some of the game's many NPCs, Tube Town offers a welcome rest between the trials and tribulations of the slopes.
                {/* Whether you want to try out a different rider, have a go at beating one of the many mini games, have a chit chat at the shops, or listen to the game’s extra bouncy soundtrack, Tube Town offers a welcome rest between the trials and tribulations of the slopes. */}
            </p>
        </>,
        outro: 'Is this why the cool kids invented the term "chillax"?',
    },
    // Mini Games
    {
        imgSrc: <StaticImage
            alt=""
            src="../../images/homepage/details/bonus-circle-shots.jpeg"
            // src="../../images/placeholders/placeholder-default.png"
            layout="fullWidth"
        />,
        heading: 'A bonanza of extra fun mini games',
        intro: 'Unlock and master a bonanza of snow tube themed mini games.',
        paragraphs: <>
            <p>
                Whether it's time to deliver Santa's presents, collect grandma's Christmas decorations, or have a snowball match with Frosty, you totally need to have a go at beating the many snow tube themed mini games Tube Town has to offer.
            </p>
            <p>
                There's racing, collecting and target practice!
            </p>
        </>,
        outro: 'Which mini game will be your favourite?',
    },
    // Soundtrack
    {
        // imgSrc: <StaticImage
        //     alt=""
        // src="../../images/placeholders/placeholder-default.png"
        //     layout="fullWidth"
        // />,
        imgSrc: <img
            src={soundtrackPolarHeadphones}
            alt="Polar listening to the game's soundtrack"
            style={{borderBottom: '3px solid black'}}
        />,
        heading: 'A super-happy, extra-bouncy, ultra-catchy soundtrack',
        intro: 'Immerse yourself in the game\'s original soundtrack, whether on the slopes, in Tube Town, or at the Jukebox.',
        // intro: 'Immerse yourself in the game\'s super happy, extra bouncy, ultra catchy, original soundtrack whether on the slopes or at the jukebox.',
        paragraphs: <>
            <p>
                Super Snow Tubes features a super-happy, extra-bouncy, ultra-catchy soundtrack of over 15 original tunes, all by expert composer <a href="https://www.kashdanmusic.com/" target="_blank" rel="noreferrer">Joshua Kashdan</a>.
            </p>
            <p>
                Extra catchy tunes - earworms guaranteed!
            </p>
        </>,
        outro: 'Which track will be stuck in your head?',
    },
    // 90s Homage
    {
        imgSrc: <StaticImage
            alt=""
            // src="../../images/homepage/details/homage.png"
            src="../../images/homepage/overview/screenshot-snow.png"
            //src="../../images/placeholders/placeholder-default.png"
            layout="fullWidth"
        />,
        heading: 'A wholesome homage to the 90’s childhood',
        intro: 'Enjoy retro-cute pixel art in a wholesome homage to the 90’s childhood.',
        paragraphs: <>
            <p>
                With its cute and charming pixel art style, and its simple, yet addictive gameplay, Super Snow Tubes is an homage to the winter sports classics of the early to late 90s.
            </p>
            <p>
                If you loved playing the classic winter sports games of the DOS era (such as Ski or Die and SkiFree), or you have fond memories of the fun racers of the Nintendo 64 era (such as Snowboard Kids, Mario Kart and Diddy Kong Racing), then Super Snow Tubes definitely is a title you do not want to miss!
            </p>
        </>,
        outro: 'Are you feeling the nostalgia?',
    },
];

// markup
const DetailSection = () => {

    // Get the images
    //     const data = useStaticQuery(graphql`
    //         query {
    //             allImageSharp {
    //                 edges {
    //                     node {
    //                         fluid(maxWidth: 800) {
    //                             ...GatsbyImageSharpFluid
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //   `);

    // console.log(data.allImageSharp.edges);

    // console.log(data.allImageSharp.edges[0].node.fluid);
    return (
        <div className="details">

            {/* <GatsbyImage
                image={data.allImageSharp.edges[0]}
                alt="Alt"
            /> */}

            {/* {data?.allImageSharp?.edges.map(({ node, index }) => {
                const img = getImage(node.fluid);
                return (<GatsbyImage
                    key={index}
                    alt="Alt"
                    image={img}
                />);
            })
            } */}

            {detailSections.map(section => (

                <div
                    className="section-detail"
                    key={section.heading}
                >

                    <div className="image">
                        {section.imgSrc}
                    </div>

                    <div className="text">

                        <h1>
                            {section.heading}
                        </h1>

                        <p className="intro">
                            {section.intro}
                        </p>

                        <>
                            {section.paragraphs}
                        </>

                        <p className="outro">
                            {section.outro}
                        </p>

                    </div>

                </div>

            ))}

        </div>
    )
}

export default DetailSection
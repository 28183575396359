import * as React from "react"

// Styles
import "../../styles/homepage/homepage_section-reviews.scss"

// markup
const ReviewsSection = () => {
    return (
        <div className="homepage_section-dark section-reviews">

            <div className="wrapper-narrow">

                <h1>
                    Rave Reviews
                </h1>

                <p>
                    Not even out yet, Super Snow Tubes already has received some rave reviews from a number of highly impartial and not at all biased super fans...
                </p>

                <blockquote>
                    <q>
                        The best game I ever played!
                    </q>
                    <span className="author">
                        100% ~ My Mom
                    </span>
                </blockquote>

                <blockquote>
                    <q>
                        You better buy this game! My son spent ages making it, when he could have been getting a real job. Good for nothing that boy is, I am telling you, good for nothing. Unlike this game... this game is the best! But pshh... don't tell him I said that, wouldn't want him getting any ideas...
                    </q>
                    <span className="author">
                        10/10 ~ My Dad
                    </span>
                </blockquote>

                {/* <blockquote>
                    <q>
                        This game sure looks gorgeous! If only he'd put that much effort into his day job... *sigh*
                    </q>
                    <span className="author">
                        5 Stars ~ My Boss
                    </span>
                </blockquote> */}

                <blockquote>
                    <q>
                        Goob Husky look floofy.<br/>
                        Wanno be her fren.<br/>
                        {/* Husky floofer so cute. Wanna be her fren.<br/> */}
                        Can boop snoot!<br/>
                        *Awoo*
                        {/* That husky girly sure is cute, I wonder if she's single? *woof* */}
                    </q>
                    <span className="author">
                        5 Stars ~ My Dog
                    </span>
                </blockquote>

            </div>

        </div>
    )
}

export default ReviewsSection
import * as React from "react"

import { StaticImage, } from 'gatsby-plugin-image'

// Styles
import "../../styles/homepage/homepage_section-overview.scss"

// markup
const OverviewSection = () => {
    return (
        <div className="homepage_section-white section-overview">

            <div className="wrapper-wide">

                <div className="header">

                    <h1>
                        Retro-Cute Animal Racing
                    </h1>

                    <h2>
                        A wholesome homage to the winter sports classics of the 90s
                    </h2>

                </div>

                <div className="leftRight">

                    <div className="image">

                        <StaticImage
                            alt=""
                            src="../../images/homepage/overview/screenshot-snow.png"
                            layout="fullWidth"
                        />

                    </div>

                    <div className="text">

                        <p>
                            Join Polar, Pengu, Seal and their adorable animal buddies in a fiery tubing competition that takes you through a wonderful world of colorful race courses, ripe with hidden shortcuts.
                        </p>

                        <p>
                            Outmaneuver {/*and overtake*/}your opponents, avoid bumpy blockades, and get ahead of the field on a mission to answer the ultimate question: <em>Who will become the Champ of Snow Tube Mountain?</em>
                        </p>

                        <p>
                            With its cute and charming pixel art style, its extra catchy soundtrack{/*, its intuitive controls*/}, and its simple, yet addictive gameplay, <em>Super Snow Tubes</em> is a wholesome homage to the winter sports classics of the early to late 90s, such as <strong>Ski or Die</strong>, <strong>Ski Free</strong> and <strong>Snowboard Kids</strong>.
                        </p>
                        <p>
                            A must for anyone who loved the classic winter sports titles back in the day!
                        </p>

                    </div>

                </div>

                <StaticImage
                    alt="The initial trio"
                    src="../../images/homepage/features-trio.png"
                />

            </div>

        </div>
    )
}

export default OverviewSection
import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

// Styles
import "../../styles/homepage/homepage_section_influences.scss"

// markup
const InfluencesSection = () => {

    return (
        <div className="homepage_section-white section-influences">

            <div className="wrapper-wide">

                <h1>
                    Influences
                </h1>

                <h2>
                    Inspired by the winter sports classics of the 90s
                </h2>

                <p>
                    It is often said that the best games feel familiar, memorable and unique. They remind us of the things we know and cherish, while adding new and fresh ideas into the mix.
                </p>

                {/* <p>
                    In case of Super Snow Tubes, the inspiration mainly comes from the winter sports classics of the early to late 90s. But eager players will notice that other influences are present as well, such as the fan favourite fun racer Diddy Kong Racing, and the Mario Kart series.
                </p> */}

                <div className="columns">

                    <div className="column">

                        <StaticImage
                            alt=""
                            src="../../images/homepage/inspiration/ski-or-die.png"
                            layout="fullWidth"
                        />

                        <h3>
                            Ski or Die
                        </h3>

                        <h4>
                            1990 &middot; MS-DOS, Amiga, Commodore 64, NES
                        </h4>

                        <p>
                            <em>Ski or Die</em> is a 1990 winter sports game by Electronic Arts. It consists of five mini-games, which can be played individually or in a set sequentially: Halfpipe-snowboarding, inflatable sled racing, aerial skiing, downhill skiing, and snowball fights.
                        </p>
                        <p>
                            This is where the core inspiration for <em>Super Snow Tubes</em> comes from. Specifically, the mini-game <em>Inner Tube Trash</em>, where you control a fat and fun, colorful tube on a snowy mountain course, while battling an AI opponent for first place.
                        </p>

                    </div>

                    <div className="column">

                        <StaticImage
                            alt=""
                            src="../../images/homepage/inspiration/ski-free.png"
                            layout="fullWidth"
                        />

                        <h3>
                            SkiFree
                        </h3>

                        <h4>
                            1991 &middot; MS-DOS, Windows
                        </h4>

                        <p>
                            <em>SkiFree</em> is a single-player skiing computer game created by Chris Pirih in 1991. The player controls a skier on a mountain slope, avoiding obstacles while racing against time or performing stunts for points, depending on the game mode.
                        </p>

                        <p>
                            <em>SkiFree</em> remains popular among the gaming community, and is often remembered for its Abominable Snowman, which pursues the player after they finish a full run.
                        </p>

                    </div>

                    <div className="column">

                        <StaticImage
                            alt=""
                            src="../../images/homepage/inspiration/snowboard-kids.jpeg"
                            //src="../../images/homepage/inspiration/sbk.png"
                            layout="fullWidth"
                        />

                        <h3>
                            Snowboard Kids
                        </h3>

                        <h4>
                            1998, 1999 &middot; Nintendo 64, PlayStation
                        </h4>

                        <p>
                        <em>Snowboard Kids</em> is a snowboarding game for the Nintendo 64 developed by Racdym and published by Atlus. Many reviewers compared its style to that of the Mario Kart series.
                        </p>
                        <p>
                            An enhanced port, <em>Snowboard Kids Plus</em>, was released in 1999 for the PlayStation. Later in 1999, the sequel, <em>Snowboard Kids 2</em>, was released.
                        </p>

                        <p>
                            These games had a huge impact on <em>Super Snow Tubes</em>. Specifically, the multiple laps, the lift that takes you back to the top, the offensive and defensive items, and the boss races, as well as the overall, cute and charming style of the game, plus its uniquely memorable soundtrack.
                        </p>

                    </div>

                    <div className="column">

                        <StaticImage
                            alt=""
                            src="../../images/homepage/inspiration/diddy-kong-racing.jpeg"
                            layout="fullWidth"
                        />

                        <h3>
                            Diddy Kong Racing
                        </h3>

                        <h4>
                            1997, 2007 &middot; Nintendo 64, Nintendo DS
                        </h4>

                        <p>
                            <em>Diddy Kong Racing</em> is a racing game for the Nintendo 64 developed and published by Rare. It was later ported to the Nintendo DS with some changes.
                            {/* The game is set on Timber's Island and revolves around Diddy Kong and his friends' attempt to defeat the intergalactic antagonist, a wizarding pig named Wizpig, through winning a series of races. */}
                        </p>

                        <p>
                            The game features five worlds with four racetracks each, and the ability to control a car, hovercraft, or aeroplane.
                        </p>

                        <p>
                            This game, while fairly different from the other titles listed here, also had a noticeable impact on <em>Super Snow Tubes</em>. Specifically, the variety of different items, the boss races, the unlocking of tracks, and the overall, cute and charming style of the game, plus its quirky soundtrack.
                        </p>

                    </div>

                </div>


            </div >

        </div >
    )
}

export default InfluencesSection
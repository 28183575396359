import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSteam
} from '@fortawesome/free-brands-svg-icons'
import {
    faNewspaper,
    faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'

// Styles
import "../../styles/homepage/homepage_section-top.scss"

// markup
const TopSection = () => {
    return (
        <div className="section-top">

            <div className="wrapper-my">

                <div className="image">

                    <StaticImage
                        alt=""
                        src="../../images/homepage/homepage-top-snow-scene.png"
                    />

                    {/* <StaticImage
                    alt=""
                    src="../../images/homepage/sst-illustration.png"
                /> */}

                </div>

                <div className="text">

                    <div className="text-content">

                        <h1>
                            <em>Super</em> Snow Tubes
                        </h1>

                        <h2>
                            Join Polar, Pengu, Seal and their adorable animal buddies for a fun and fierce snow tubing tournament!
                        </h2>

                        <p>
                            {/* Take to the mountains in this satisfyingly cute, retro-inspired fun racer, starring a cast of quirky and adorable animal buddies in a fun and fierce battle for first place. Ready, set, go! */}

                            {/* Set in a wonderful world of colorful race courses, this satisfyingly cute, retro-inspired fun racer features adorable pixel art paired with an ultra-catchy soundtrack in a wholesome homage to the classic winter sports games of the 90s. */}
                            {/* <em>Are you ready? Are you set? Then lets tube!</em> */}

                            {/* Take to the slopes in this retro-cute fun racer, featuring pretty pixels, quirky characters, catchy tunes, and a wonderful world of colorful courses in a wholesome homage to the winter sports classics of the 90s era. */}
                            
                            Take to the slopes in this retro-cute fun racer, featuring pretty pixels, quirky characters, colorful courses and catchy tunes in a wholesome homage to the winter sports classics of the 90s era.
                        </p>

                        <p>
                            What are you waiting for? <em>Ready, set, tube!</em>
                        </p>

                        <div className="insp">
                            {/* Inspired by:&nbsp;  */}
                            Influences:&nbsp; <strong>Ski or Die</strong> · <strong>Ski Free</strong> · <strong>Snowboard Kids</strong>
                        </div>

                        <a
                            className="button"
                            href="https://store.steampowered.com/app/1515590/Super_Snow_Tubes/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faSteam} className="icon" />
                            Wishlist on Steam
                        </a>

                        <a
                            className="button button-ghost"
                            href="/faq"
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
                            FAQ
                        </a>

                        {/* <a
                            className="button button-ghost"
                            href="/presskit"
                        >
                            <FontAwesomeIcon icon={faNewspaper} className="icon" />
                            Press Kit
                        </a> */}

                    </div>

                </div>

            </div >

        </div>
    )
}

export default TopSection
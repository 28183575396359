import * as React from "react"

// SEO
import Seo from '../components/Seo'

// Site
import SiteHeader from '../components/SiteHeader'
import SiteFooter from '../components/SiteFooter'

// Sections
import SectionTop from '../components/homepage/Homepage_Section-Top'
import SectionSteam from '../components/homepage/Homepage_Section-Steam'
import SectionOverview from '../components/homepage/Homepage_Section-Overview'
import SectionFeatures from '../components/homepage/Homepage_Section-Features'
import SectionDetailsA from '../components/homepage/Homepage_Section-Details-A'
import SectionDetailsB from '../components/homepage/Homepage_Section-Details-B'
import SectionReviews from '../components/homepage/Homepage_Section-Reviews'
//import SectionSoundtrack from '../components/homepage/Homepage_Section-Soundtrack'
import SectionJokes from '../components/homepage/Homepage_Section-Jokes'
//import SectionRiders from '../components/homepage/Homepage_Section_Riders'
import SectionScreenshots from '../components/homepage/Homepage_Section_Screenshots'
import SectionInfluences from '../components/homepage/Homepage_Section-Influences'
import SectionSteamReminder from '../components/homepage/Homepage_Section_SteamReminder'
import SectionFollow from '../components/homepage/Homepage_Section_Follow'

// Fonts - Ubuntu
import "@fontsource/ubuntu/400.css"
import "@fontsource/ubuntu/500.css"
import "@fontsource/ubuntu/700.css"

// Fonts - Cabin Condensed
import "@fontsource/cabin-condensed/400.css"
import "@fontsource/cabin-condensed/500.css"
import "@fontsource/cabin-condensed/600.css"
import "@fontsource/cabin-condensed/700.css"

// Fonts - Open Sans
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"

// Styles
import "../styles/reset.scss"
import "../styles/styles.scss"

// markup
const IndexPage = () => {

  return (
    <main className="index-main">

      {/* SEO */}
      <Seo
        title="Ready, set, tube!"
        //title="Take to the mountains"
        description=""
        slug=""
      />

      <SiteHeader />

      <SectionTop />

      <SectionOverview />

      <SectionSteam />

      <SectionDetailsA />

      <SectionReviews />

      {/* <SectionDetailsB /> */}
      
      {/* <SectionJokes /> */}

      {/* <SectionSoundtrack /> */}
      {/* <SectionRiders /> */}

      <SectionInfluences />

      <SectionScreenshots />
      
      <SectionFeatures />

      <SectionSteamReminder />

      <SectionFollow />

      <SiteFooter />

    </main>
  )
}

export default IndexPage

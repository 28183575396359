import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

// Styles
import "../../styles/homepage/homepage_section-features.scss"

// markup
const FeaturesSection = () => {
    return (
        <div className="homepage_section-white section-features">

            <div className="wrapper-narrow">

                <div className="header">

                    <h1>
                        Key Features
                    </h1>

                    <h2>
                        What to expect?
                    </h2>

                </div>

                <ul>
                    <li>
                        Play a quirky cast of <strong>adorable animal riders</strong>, from penguin to polar bear, each with their very own, unique handling - based on the game's Star Stats system
                    </li>
                    <li>
                        Race and explore a wonderful world of <strong>colorful mountain courses</strong>, ripe with hidden shortcuts just waiting to be discovered
                    </li>
                    <li>
                        <strong>Overtake your competition</strong> in a fun and fierce battle for first place - utilising intuitive controls that are easy to pick up, but difficult to master
                    </li>
                    <li>
                        Outmaneuver fierce foes, <strong>avoid bumpy blockades</strong>, and stay on track at all cost to maximise your tube's speed and get ahead of the field
                    </li>
                    <li>
                        Take a break from the slopes and relax between races in Tube Town, the game's <strong>extra cozy hub world</strong> - located right at the bottom of Snow Tube Mountain
                    </li>
                    <li>
                        Save up coins, hit the shops, and unlock a bonanza of <strong>snow tube themed mini games</strong> and other goodies that you do not want to miss
                    </li>
                    <li>
                        Immerse yourself in the game's super happy, extra bouncy, <strong>ultra catchy soundtrack</strong>, whether on the slopes or at the jukebox; earworms guaranteed
                    </li>
                    <li>
                        Enjoy a colorful, <strong>retro-cute pixel art style</strong> with a modern spin - merging the best of then and now - in a wholesome homage to the 90s childhood
                    </li>
                </ul>

                <StaticImage
                    alt="The initial trio"
                    src="../../images/homepage/features-trio.png"
                />

            </div>

        </div>
    )
}

export default FeaturesSection
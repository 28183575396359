import * as React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTwitter,
    faInstagram,
    faFacebookF,
    //faYoutube,
    faTiktok
} from '@fortawesome/free-brands-svg-icons'

// Image
import followPenguWave from '../../images/homepage/follow-pengu-wave.gif'

// Styles
import "../../styles/homepage/homepage_section_follow.scss"

// markup
const FollowSection = () => {
    return (
        <div className="homepage_section-white section-follow">

            <div className="wrapper-narrow">

                <h1>
                    Social Profiles
                </h1>

                <h2>
                    Follow Super Snow Tubes
                </h2>

                <p>
                    Keep up with the latest development news, sneak peeks, announcements and more.
                </p>

                <div className="socials">

                    <a
                        className="social"
                        href="https://twitter.com/GameDevMix"
                        aria-label="Twitter"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: "#55ACED" }}
                    >
                        <FontAwesomeIcon icon={faTwitter} className="icon" />
                    </a>

                    <a
                        className="social"
                        href="https://instagram.com/GameDevMix"
                        aria-label="Instagram"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: "#E4405F" }}
                    >
                        <FontAwesomeIcon icon={faInstagram} className="icon" />
                    </a>

                    <a
                        className="social"
                        href="https://tiktok.com/@GameDevMix"
                        aria-label="TikTok"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: "#01EAE4" }}
                    >
                        <FontAwesomeIcon icon={faTiktok} className="icon" />
                    </a>

                    <a
                        className="social"
                        href="https://facebook.com/GameDevMix"
                        aria-label="Facebook"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: "#4064AC" }}
                    >
                        <FontAwesomeIcon icon={faFacebookF} className="icon" />
                    </a>

                    {/* <a
                        className="social"
                        href="https://www.youtube.com/channel/UC9V9OI9iAAa0p3HIy53Q5-g"
                        aria-label="YouTube"
                        target="_blank"
                        rel="noreferrer"
                        style={{backgroundColor: "#DE271E"}}
                        >
                        <FontAwesomeIcon icon={faYoutube} className="icon" />
                    </a> */}

                </div>

                <img
                    src={followPenguWave}
                    alt="Pengu waving at you"
                />

            </div>

        </div>
    )
}

export default FollowSection
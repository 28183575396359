import * as React from "react"

// Image carousel
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// Image files (same as presskit page)
import imageSnow from '../../images/presskit/screenshots/screenshot_snow.png'
import imagePurple from '../../images/presskit/screenshots/screenshot_purple.png'
//import imageRiderSelect from '../../images/presskit/screenshots/screenshot_rider-select.png'
import imageGrass from '../../images/presskit/screenshots/screenshot_grass.png'
import imageJapan from '../../images/presskit/screenshots/screenshot_japan.png'
import imageArctic from '../../images/presskit/screenshots/screenshot_arctic.png'
//import imageCourseSelect from '../../images/presskit/screenshots/screenshot_course-select.png'
import imageDesert from '../../images/presskit/screenshots/screenshot_desert.png'
import imageBeach from '../../images/presskit/screenshots/screenshot_beach.png'
import imageMines from '../../images/presskit/screenshots/screenshot_mines.png'

// Styles
import "../../styles/homepage/homepage_section-screenshots.scss"

// Image carousel event handler
const handleDragStart = (e) => e.preventDefault();

// Image carousel images
const items = [
    <img
        src={imagePurple}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    <img
        src={imageSnow}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    // <img
    //     src={imageRiderSelect}
    //     alt="Screenshot"
    //     onDragStart={handleDragStart}
    //     role="presentation"
    // />,
    <img
        src={imageGrass}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    <img
        src={imageJapan}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    <img
        src={imageArctic}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    // <img
    //     src={imageCourseSelect}
    //     alt="Screenshot"
    //     onDragStart={handleDragStart}
    //     role="presentation"
    // />,
    <img
        src={imageDesert}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    <img
        src={imageBeach}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
    <img
        src={imageMines}
        alt="Screenshot"
        onDragStart={handleDragStart}
        role="presentation"
    />,
];

// markup
const ScreenshotsSection = () => {

    return (
        <div className="homepage_section_screenshots">

            <div className="wrapper-wide">

                <div className="header">

                    <h1>
                        Screenshots
                    </h1>

                    <p>
                        Enjoy a sneak peek of some of the game's courses and menus below.
                    </p>

                </div>

                <AliceCarousel
                    mouseTracking
                    items={items}
                    autoPlay={true}
                    autoPlayInterval={3000}
                    infinite={true}
                    responsive={{items:1}}
                />

            </div>

        </div>


    )
}

export default ScreenshotsSection
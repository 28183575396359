import * as React from "react"

import { StaticImage, } from 'gatsby-plugin-image'

// Images (Gifs can only be used like this, I think)
import ridersFrames from '../../images/homepage/details/riders-frames.gif'
import coursesFrames from '../../images/homepage/details/courses-frames.gif'

// Styles
import "../../styles/homepage/homepage_section-detail.scss"

const detailSections = [
    // Riders
    {
        // imgSrc: <StaticImage
        //     alt=""
        //     src="../../images/homepage/details/riders-frames.gif"
        //     // src="../../images/placeholders/placeholder-default.png"
        //     layout="fullWidth"
        // />,
        imgSrc: <img
            src={ridersFrames}
            alt="Polar listening to the game's soundtrack"
        />,
        heading: 'A cast of quirky and adorable animal riders',
        intro: 'Play a cast of quirky and adorable animal buddies, from Penguin to Polar Bear.',
        paragraphs: <>
            <p>
                Riding on fat and fun, colorful snow tubes, each rider handles differently, based on their Star Stats: Speed, Turn and Bump. Simply choose whoever best suits your preferred playstyle!
            </p>
        </>,
        outro: 'Who will be your go-to rider?',
    },
    // Courses
    {
        // imgSrc: <StaticImage
        //     alt=""
        //     src="../../images/homepage/details/course-select.png"
        //     // src="../../images/placeholders/placeholder-default.png"
        //     layout="fullWidth"
        // />,
        imgSrc: <img
            src={coursesFrames}
            alt="Polar listening to the game's soundtrack"
        />,
        heading: 'A world of colorful race courses, ripe with hidden shortcuts',
        intro: 'Explore a world of colorful race courses, find the fastest routes, and discover hidden shortcuts.',
        paragraphs: <>
            <p>
                Riding the brand-new and improved “super” snow tube, the fun and fierce battle for first place takes you on a grand tour through the seasons and all around the world!
            </p>
            <p>
                From snowy mountains and arctic ice shelves to lush forests, dry deserts, pirate beaches and candy kingdoms, there’s a large variety of colorful courses just waiting to be raced. Legend has it that the best snow tubers may even make it to the moon!
            </p>
        </>,
        outro: 'Who said snow tubes only go on snow?',
    },
    // Obstacles
    {
        imgSrc: <StaticImage
            alt=""
            src="../../images/homepage/details/obstacle-tree.png"
            layout="fullWidth"
        />,
        heading: 'A host of bumpy blockades and fierce foes to avoid and outmaneuver',
        //heading: 'A plethora of bumpy blockades and fierce foes to avoid and outmaneuver',
        intro: 'Outmaneuver fierce foes and avoid bumpy blockades to stay on course.',
        // Avoid obstacles and the local wildlife to stay on course.',
        paragraphs: <>
            <p>
                In Super Snow Tubes, race courses are ridden with  obstacles and all kinds of bumpy wildlife that just loves to get in your way.
            </p>
            <p>
                Whether it's trees, rocks, and walls, or barriers, bunnies and even ninjas, you better stay on your toes!
            </p>
            <p>
                Avoid obstacles and traps, and outmanouver fierce foes to keep your speed at all cost. Or bump into them for some extra bumper fun!
            </p>
        </>,
        outro: 'Can you manage a run with zero bumps?',
        // outro: 'Which obstacles will you bump into?',
    },
    // TODO: Items
    // {
    //     imgSrc: <StaticImage
    //         alt=""
    //         src="../../images/homepage/details/obstacle-tree.png"
    //         layout="fullWidth"
    //     />,
    //     heading: '???',
    //     paragraphs: <>
    //         <p>
    //             ???
    //         </p>
    //         <p>
    //             ???
    //         </p>
    //         <p>
    //             ???
    //         </p>
    //     </>,
    //     outro: 'Question?',
    // },
];

// markup
const DetailSection = () => {

    // Get the images
    //     const data = useStaticQuery(graphql`
    //         query {
    //             allImageSharp {
    //                 edges {
    //                     node {
    //                         fluid(maxWidth: 800) {
    //                             ...GatsbyImageSharpFluid
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //   `);

    // console.log(data.allImageSharp.edges);

    // console.log(data.allImageSharp.edges[0].node.fluid);
    return (
        <div className="details">

            {/* <GatsbyImage
                image={data.allImageSharp.edges[0]}
                alt="Alt"
            /> */}

            {/* {data?.allImageSharp?.edges.map(({ node, index }) => {
                const img = getImage(node.fluid);
                return (<GatsbyImage
                    key={index}
                    alt="Alt"
                    image={img}
                />);
            })
            } */}

            {detailSections.map(section => (

                <div
                    className="section-detail"
                    key={section.heading}
                >

                    <div className="image">
                        {section.imgSrc}
                    </div>

                    <div className="text">

                        <h1>
                            {section.heading}
                        </h1>

                        <p className="intro">
                            {section.intro}
                        </p>

                        <>
                            {section.paragraphs}
                        </>

                        <p className="outro">
                            {section.outro}
                        </p>

                    </div>

                </div>

            ))}

        </div>
    )
}

export default DetailSection